const Recognitions = [
    {
      imgURL:
        "/assets/img/recognition/frame_1.webp",
      imgAlt: "Recognition-1"
    },
    {
      imgURL:
        "/assets/img/recognition/frame_2.webp",
      imgAlt: "Recognition-2"
    },
    {
      imgURL:
        "/assets/img/recognition/frame_3.webp",
      imgAlt: "Recognition-3"
    },
    {
      imgURL:
        "/assets/img/recognition/frame_4.webp",
      imgAlt: "Recognition-4"
    },
    {
      imgURL:
        "/assets/img/recognition/frame_5.webp",
      imgAlt: "Recognition 5"
    }
  ];
  
  export default Recognitions;
  