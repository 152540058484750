const TrustedBys = [
    {
        imgURL:
            "/assets/img/brands/intra-corp-logo-1.webp",
        imgAlt: "Trusted By 1"
    },
    {
        imgURL:
            "/assets/img/brands/Cohen-logo.webp",
        imgAlt: "Trusted By 2"
    },
    {
        imgURL:
            "/assets/img/brands/patent-pc-1.webp",
        imgAlt: "Trusted By 3"
    },
    {
        imgURL: "/assets/img/brands/building-lens.webp",
        imgAlt: "Trusted By"
    },
    {
        imgURL: "/assets/img/brands/mitre-logo-removebg-preview.webp",
        imgAlt: "Recognition 5"
    },
    {
        imgURL: "/assets/img/brands/Screenshot-2023-06-25-at-9.16-1.webp",
        imgAlt: "Trusted By 6"
    },
    {
        imgURL: "/assets/img/brands/image-13.webp",
        imgAlt: "Trusted By 7"
    },
    {
        imgURL: "/assets/img/brands/logo-2-2.webp",
        imgAlt: "Trusted By 8"
    },
    {
        imgURL: "/assets/img/brands/image-14.webp",
        imgAlt: "Trusted By 9"
    },
    {
        imgURL: "/assets/img/brands/PLLAY-Logo-Ver2.webp",
        imgAlt: "Trusted By 10"
    },
    {
        imgURL: "/assets/img/brands/image-15.webp",
        imgAlt: "Trusted By 11"
    },
    {
        imgURL: "/assets/img/brands/image-18.webp",
        imgAlt: "Trusted By 12"
    },
    {
        imgURL: "/assets/img/brands/logo-ccf.svg",
        imgAlt: "Trusted By 13"
    },
    {
        imgURL: "/assets/img/brands/footer_logo-1.webp",
        imgAlt: "Trusted By 14"
    },
    {
        imgURL: "/assets/img/brands/stack-logo-1.webp",
        imgAlt: "Trusted By 15"
    },
    {
        imgURL: "/assets/img/brands/logo-did-color@2x-55x98-1.webp",
        imgAlt: "Trusted By 16"
    },
    {
        imgURL: "/assets/img/brands/mirro-logo-1.webp",
        imgAlt: "Trusted By 17"
    },
    {
        imgURL: "/assets/img/brands/adapt-logo-1.webp",
        imgAlt: "Trusted By 18"
    },
    {
        imgURL: "/assets/img/brands/tran-logo.webp",
        imgAlt: "Trusted By 19"
    },
    {
        imgURL: "/assets/img/brands/intra-corp-logo-1.webp",
        imgAlt: "Trusted By"
    },

    { imgURL: "/assets/img/brands/Cohen-logo.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/patent-pc-1.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/building-lens.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/mitre-logo-removebg-preview.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/Screenshot-2023-06-25-at-9.16-1.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/image-13.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/logo-2-2.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/image-14.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/PLLAY-Logo-Ver2.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/icon2.webp", imgAlt: "Trusted By" },


    { imgURL: "/assets/img/brands/icon1.webp", imgAlt: "Trusted By" },
];

export default TrustedBys;
