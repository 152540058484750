const TrustedClients = [
    {
        clientLogo: "http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture1-1.png",
        ceoText: "Kim Boyce",
        ceoDesignation: "CEO",
        ceoDescription: `
        Reflect Scientific (Symbol: RSCF) is a provider of diverse products and services for the biotech, pharma, and transportation industries. We have been using early trials of PowerPatent® solutions to draft and create our cases and found that we can generate initial patent drafts in about ten minutes. The voluminous first drafts are then edited and polished in a few hours. Even with lawyer review, the cost is quite low and the quality is great. Being a public company, our regulatory people are always amazed by our prolific patent count and efficiency of our IP spend. PowerPatent is our secret weapon.
        `
    },
    {
        clientLogo: "http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture2.png",
        ceoText: "William Moon",
        ceoDesignation: "VP Technology & Operations",
        ceoDescription: `
        Nu-Med Plus (Symbol NUMD) commercializes a patented and patent-pending technology to safely deliver precision doses of nitric oxide. During beta testing, we save time and effort with PowerPatent to generate first draft quickly, and then we focus our energy and expertise on intellectually rigorous and valuable tasks such as writing strategic claims and improving the AI-generated application by explaining the nuances of the invention that the AI would not know about. I estimate this software has shaved weeks from the documentation of our inventions so that our outside counsel can file for us.
        `
    },
    {
        clientLogo: "http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture3.png",
        ceoText: "Jesse Kim",
        ceoDesignation: "CEO",
        ceoDescription: `
        EOFlow (Symbol: 294090.KQ) is passionate about turning technology into products that people can use. Our EOPatch wearable disposable insulin pump is only the first of many products that are in our lab and on our drawing boards. We use PowerPatent® solutions to do high-quality first draft applications. We found the software easy to use. Our outside counsel loves the patent workflow, all within our IP budget. We believe that technology can improve people’s lives, and our use of PowerPatent’s AI legal-tech is an easy decision for a public company such as ours.
        `
    },
    {
        clientLogo: "http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture4.png",
        ceoText: "Brian Cronquist",
        ceoDesignation: "CEVP Technology & IPO",
        ceoDescription: `
        MonolithIC 3D is an IP company that offers 1,000x Improvement in Computer Systems by Bridging the Processor-Memory Gap using 3D chip technology. We license our IP to the largest semiconductor companies in the world. We do all the preparation and prosecution of patents in-house and we rely on PowerPatent to draft and create our cases. It can quickly create reams of detailed descriptions, from which we can finalize. This effective tool increases our productivity. I credit PowerPatent with giving me more time at the end of the day to invent more.
        `
    },
    {
        clientLogo: "http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture6.png",
        ceoText: "Khanh Le",
        ceoDesignation: "COO & Co-Founder",
        ceoDescription: `
        MonolithIC 3D is an IP company that offers 1,000x Improvement in Computer Systems by Bridging the Processor-Memory Gap using 3D chip technology. We license our IP to the largest semiconductor companies in the world. We do all the preparation and prosecution of patents in-house and we rely on PowerPatent to draft and create our cases. It can quickly create reams of detailed descriptions, from which we can finalize. This effective tool increases our productivity. I credit PowerPatent with giving me more time at the end of the day to invent more.
        `
    },
    {
        clientLogo: "http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture7.png",
        ceoText: "Ting Chang",
        ceoDesignation: "Executive VP & Co-Founder",
        ceoDescription: `
        MicroNOC turns buildings into grid synchronized property based clean virtual power stations to reduce energy waste. We balance your rates and balance the grid. Since our mission is to save customer cost, we are always on the lookout for innovative solutions to cut our own expenses. With PowerPatent, we were able to balance our IP cost with quality and we recently received our first patent. PowerPatent solution is the way to protect your Unicorn idea.
        `
    },
    {
        clientLogo: "http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture8.png",
        ceoText: "Gayle Shaw Jenkins",
        ceoDesignation: "Founder",
        ceoDescription: `
        My name is Gayle Jenkins and I am a web developer, artist, and idea-maker based in Fairborn, Ohio. I enjoy the puzzle of exploring & understanding the problems and limitations that exist in systems and then the challenge of envisioning and creating solutions. Our company created our IP portfolio at insanely low cost using PowerPatent. We are no expert at patenting, and we were able to use PowerPatent® to create IP inexpensively and in a timely manner. This is a great tool for startup founders. I highly recommend PowerPatent solutions for patent creation.
        `
    },
    {
        clientLogo: "http://wp.powerpatent.com/wp-content/uploads/2023/10/ni.png",
        ceoText: "Linbo Yang",
        ceoDesignation: "Principal Engineer",
        ceoDescription: `
        AIXScan creates new digital tomosynthesis (DTS) solution that eliminates overlapping anatomy or composite artifact. DTS facilitates improved lesion detection, depth localization and contrast resolution compared with regular radiography. We used PowerPatent® solutions to draft 12 high-quality utility applications. PowerPatent is easy to use and its attorney collaboration ability ensures we have white-glove service. We are very happy with PowerPatent IP solutions.
        `
    },
]
export default TrustedClients;
